<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Blog</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Blog Category</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row>
                <b-col md="4">
                    <b-card type="default" header-classes="bg-transparent">
                        <b-form style="text-align: left">
                            <b-row>
                                <b-col md="12">
                                    <label>Blog Category *</label>
                                    <b-input type="text" placeholder="Add Blog Category" v-model="blogCat.category"></b-input>
                                </b-col>
                                <b-col md="12" class="mt-4">
                                    <base-button block type="primary" @click="addCategory">Add Blog Category</base-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
                <b-col md="8">
                    <b-card type="default" header-classes="bg-transparent">
                        <!-- <b-row  class="mb-2">
                            <b-col md="8"></b-col>
                            <b-col md="4"><base-button block type="primary" @click="csvExport(csvData)">Export to CSV</base-button></b-col>
                        </b-row> -->
                        <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        :line-numbers="true"
                        :search-options="{
                        enabled: true,
                        placeholder: 'Search this table',
                        }"
                        :pagination-options="{
                        enabled: true,
                        mode: 'records'
                        }"
                        >
                        <template slot="table-row" slot-scope="props" style="w">
                          <span v-if="props.column.field == 'after'">
                            <base-button
                            icon type="primary"
                            size="sm"
                            @click="newsCategoryEdit(props.row.news_cat_id)"
                            >
                            <span class="btn-inner--icon"><i class="far fa-edit"></i></span>
                            </base-button>
                            <base-button
                            icon type="danger"
                            size="sm"
                            @click="checkedClick(props.row.news_cat_id)"
                            >
                            <span class="btn-inner--icon">
                              <i class="ni ni-check-bold" v-if="props.row.news_cat_status"></i>
                              <i class="ni ni-fat-remove" v-else></i>
                            </span>
                            </base-button>
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                        </template>
                        </vue-good-table>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api';
export default {
  name: 'news-category',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: false,
      checked: true,
      columns: [
        {
          label: 'Blog Category',
          field: 'news_cat_name'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ],
      blogCat: {
        id:null,
        category:null
      }
    }
  },
  computed: {
    rows () {
      return this.$store.state.newsCategoryList == null ? [] : this.$store.state.newsCategoryList
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    addCategory() {
      const self = this;
      console.log(this.blogCat);
      const api = {
      callback() {
          self.axios.post(API_URL+'admin_news_category',self.blogCat)
          .then((response) => {
            let obj = response.data
            obj.status === true ? self.$store.dispatch('alertSuccess', obj.message) : self.$store.dispatch('alertError', obj.message)
            self.$store.dispatch('getNewsCategoryShow')
            self.blogCat = {};
          })
        }
      }
      this.blogCat.category == null || this.blogCat.category == '' ? self.$store.dispatch('alertError', "Add category Name")
      : api.callback()
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.news_cat_id === id) {
          row.news_cat_status = !row.news_cat_status; 
          let active = row.news_cat_status;
          this.axios.post(API_URL+'admin_news_category_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
              this.$store.dispatch('getNewsCategoryShow');
              this.$store.dispatch('alertSuccess', message)
            }else {
              this.$store.dispatch('alertSuccess', message)
            }
          })
        }
        return row
      })
    },
    newsCategoryEdit(id) {
      let newsCategory = this.$store.state.newsCategoryList == null ? []
      : this.$store.state.newsCategoryList.find((data) => {
        return data.news_cat_id === id
      })
      this.blogCat = {
        id : newsCategory.news_cat_id,
        category: newsCategory.news_cat_name
      }
    }
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
